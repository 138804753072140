<template>
  <section
    v-if="richText || links?.length"
    :class="$style.root"
  >
    <core-text-component
      v-if="richText"
      :class="$style.introduction"
      :string="richText"
    />

    <div
      :class="$style.summary"
      v-html="$t('homepage.biennialsSummary', { editionCount: entriesFiltered?.length, yearsCount: yearsCount, participantsCount: participantCount })"
    />

    <shared-links-component
      v-if="linksFiltered(links)?.length"
      :links="linksFiltered(links)"
      :class="$style.links"
    />
  </section>
</template>

<script setup lang="ts">
import { max, min } from 'lodash-es'

const props = defineProps({
  richText: {
    type: String,
    required: false
  },
  links: {
    type: Array,
    required: false
  },
  editionsData: {
    type: Object,
    required: false
  },
  biennialsPageData: {
    type: Object,
    required: false
  },
  participantCount: {
    type: Number,
    required: false
  }
})

const entriesFiltered = ref(null)
if (props?.editionsData) {
  const editions = toRaw(props?.editionsData) || []
  const manuallySelectedEditionIndex = props?.biennialsPageData?.lastBiennialEntry?.editionIndex || props?.biennialsPageData?.currentBiennialEntry?.editionIndex
  if (!manuallySelectedEditionIndex) {
    entriesFiltered.value = editions
  } else {
    entriesFiltered.value = editions?.filter(i => i?.editionIndex <= manuallySelectedEditionIndex) || []
  }
}

const yearsCount = computed(() => {
  const years = getEditionsNavigationGroups(entriesFiltered.value)?.years?.map(i => i?.year) || []
  const minYear = min(years)
  const maxYear = max(years)
  return maxYear - minYear > 0 ? maxYear - minYear : undefined
})

const linksFiltered = (links: any) => {
  return links.filter((link: any) => {
    return getRoutePresence(getNavigationItem(link)?.link?.routeObject?.name)
  })
}
</script>

<style module>
.root {

}

.introduction {
  composes: container from global;
}

.summary {
  composes: container caption font-size-small from global;
  margin-top: var(--font-small--line-height);
}

.links {
  composes: container from global;
  margin-bottom: 0;
  margin-top: var(--font-default--line-height) !important;
}
</style>
