import qs from 'qs'

const fields = [
  'title',
  'locale'
]

const populate = {
  currentBiennialEntry: {
    fields: [
      'slug',
      'dateEnd',
      'editionIndex',
    ]
  },
  lastBiennialEntry: {
    fields: [
      'slug',
      'dateEnd',
      'editionIndex',
    ]
  }
}

export const queryBiennialPageForHomepage = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      fields,
      populate: {
        ...populate,
        localizations: {
          fields,
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-page?${query}`
}
