import qs from 'qs'

const fields = [
  'title',
  'richText',
  'locale'
]

const populate = {
  ...queryFragmentSeo,
  links: {
    on: queryFragmentLinks
  },
  items: {
    on: {
      ...queryFragmentLinks,
      'homepage.biennial': {
        populate: {
          biennialEntry: {
            fields: [
              'title',
              'slug',

              'dateStart',
              'dateEnd',
              'hideDates',

              'editionIndex',
              'country',
              'city',
              'titleAnnotation'
            ],
            populate: {
              colors: true,
              biennialConcept: {
                populate: {
                  blocks: {
                    on: {
                      ...queryFragmentFirstRichTextBlock,
                      ...queryFragmentImagesBlocks
                    }
                  }
                }
              }
            }
          },
        },
      },
      'homepage.news': {
        populate: {
          newsEntry: {
            fields: [
              'title',
              'slug',
              'datePosted'
            ],
            filters: {
              interimWebsiteOnly: {
                $ne: true
              }
            },
            populate: {
              ...queryFragmentBiennialEntriesThumbnail,
              blocks: {
                on: {
                  ...queryFragmentFirstRichTextBlock
                }
              },
              coverImage: true
            }
          }
        }
      },
      'homepage.image': {
        fields: ['richText'],
        populate: {
          image: true,
          biennialEntry: {
            fields: [
              'title',
              'slug',
              'dateStart',
              'dateEnd'
            ],
            populate: {
              colors: true
            }
          }
        }
      },
      'homepage.mux-video': {
        fields: ['richText'],
        populate: {
          muxAsset: true,
          biennialEntry: {
            fields: [
              'title',
              'slug',
              'dateStart',
              'dateEnd'
            ],
            populate: {
              colors: true
            }
          }
        }
      },
      'homepage.newsletter': {
        fields: ['label']
      },
      'homepage.countdown': {
        populate: {
          biennialEntry: {
            fields: [
              'slug',

              'title',
              'titleAnnotation',

              'note',

              'dateStart',
              'dateEnd',
              'hideDates',

              'editionIndex',
              'country',
              'city'
            ],
            populate: {
              colors: true
            }
          }
        }
      },
    }
  },
}

export const queryHomePage = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      fields,
      populate: {
        ...populate,
        localizations: {
          fields,
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `home-page?${query}`
}
