import qs from 'qs'

const fields = [
  'id',
  'locale'
]

export const queryBienniaParticipantEntriesCount = (options: queryOptions) => {
  // FIXME do i need to populate? number is not same as on live IFM
  const filters = options?.filterValue ? {
    filters: {
      biennialEntries: {
        editionIndex: {
          $lte: options?.filterValue
        }
      }
    }
  } : {}

  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      status: 'live',
      pagination: {
        page: 1,
        pageSize: 1
      },
      ...filters,
      fields
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-participant-entries?${query}`
}
